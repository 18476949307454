.webHero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 35vh;
  max-width: 100vw;
  overflow: hidden;
  background: linear-gradient(
    45deg,
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent)
  );
  animation: gradientAnimation 700s infinite;
  background-size: 3000% 100%;
  color: var(--gray);
  margin: 3%;
  margin-bottom: 0;
  border-radius: 15px;
  border: outset 2px #333;
  box-shadow: 3px 3px 6px #000;
}

.webHero-banner h1 {
  font-family: var(--f3);
  font-size: 4rem;
  letter-spacing: 0.5rem;
  margin: 0;
  padding: 1rem 0.25rem;
  text-align: center;
  animation: webBanFade 2s ease;
}

.horizontal-bar {
  overflow: hidden;
  position: relative;
  width: 100vw;
}

.bar-list {
  display: flex;
  animation: scroll 30s linear infinite;
}

.bar-item {
  padding: 1rem;
  white-space: nowrap;
  font-size: 2rem;
  font-family: var(--f4);
}

.webHero-frame {
  margin: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.webHero-frame a {
  text-decoration: none;
  margin-bottom: 2rem;
}

.webHero-frame h2 {
  margin: 0 auto 3%;
  font-family: var(--f6);
  font-size: 3rem;
  color: var(--gray);
  letter-spacing: 0.5rem;
  animation: webBanFade 2s ease;
  transition: all 0.3s ease;
  text-align: center;
}

.webHero-frame.light h2:last-child:hover,
.webHero-frame h2:last-child:hover {
  transform: scale(1.05);
  color: var(--accent);
}

.webHero-frame.light h2 {
  color: #333;
}

.chart-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.chart-row div:first-child {
  margin-right: 10%;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-155%);
  }
}

@keyframes mobileScroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-700%);
  }
}

@keyframes scrollWalk1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes scrollWalk2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-320%);
  }
}

@keyframes scrollWalk3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-85%);
  }
}

@keyframes webBanFade {
  0% {
    display: hidden;
    opacity: 0;
  }
  100% {
    display: revert;
    opacity: 1;
  }
}

@media only screen and (min-width: 1921px) {
  .bar-list {
    animation: scrollWalk3 30s linear infinite;
  }
}

@media only screen and (max-width: 1549px) {
  .bar-list {
    animation: scrollWalk1 30s linear infinite;
  }
}

@media only screen and (max-width: 1249px) {
  .bar-list {
    animation: scrollWalk2 30s linear infinite;
  }

  .chart-row {
    flex-direction: column;
  }

  .chart-row div:first-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .webHero-banner h1 {
    font-size: 3rem;
  }

  .webHero-frame h2 {
    font-size: 2.5rem;
  }

  .bar-list {
    animation: mobileScroll 30s linear infinite;
  }
}
