.projects-container {
  display: flex;
  justify-content: center;
  color: var(--gray);
}

.projects-container.light {
  color: #333;
}

.text-and-image {
  display: flex;
  align-items: center;
}

.img-and-enlarge {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5%;
}

.outer-project-column {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-column {
  width: 80%;
  margin: 3%;
}

.project-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.project-content.expanded {
  max-height: 750px;
}

.project-content img {
  height: 300px;
  border-radius: 15px;
  transition: all 0.2s ease;
  border: 2px solid #333;
  box-sizing: border-box;
}

.project-content img:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.project-content h5 {
  font-family: var(--f4);
  margin: 0.5rem 0;
  letter-spacing: 0.15rem;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}

.projects-container.light h5 {
  font-weight: bold;
}

.project-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto;
  padding: 0.5rem;
  font-family: var(--f3);
  font-size: 2rem;
  letter-spacing: 0.25rem;
  transition: all 0.2s ease;
}

.project-title-container img {
  height: 35px;
  margin-right: 10px;
}

.project-title-container:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.projects-wrapper {
  margin: 5% auto;
}

.projects-wrapper h2 {
  text-align: center;
  font-family: var(--f6);
  letter-spacing: 0.5rem;
  color: var(--gray);
  font-size: 3rem;
  padding: 0 0.5rem;
}

.projects-wrapper h3 {
  margin: 0;
}

.title.light {
  color: #333;
}

.project-content p {
  font-family: var(--f5);
  line-height: 1.75;
  font-size: 1.25rem;
  text-align: right;
}

.project-content a {
  text-decoration: none;
  color: var(--gray);
}

.full-repo-link button,
.project-content button {
  font-family: var(--f6);
  color: var(--gray);
  font-size: 1rem;
  transition: all 0.2s ease;
  letter-spacing: 0.15rem;
  background: linear-gradient(
    45deg,
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent)
  );
  animation: gradientAnimation 700s infinite;
  background-size: 3000% 100%;
  padding: 1rem;
  border: 2px solid var(--gray);
  border-radius: 10px;
}

.full-repo-link button:hover,
.project-content button:hover {
  cursor: pointer;
  transform: scale(1.03);
  background-color: rgba(51, 51, 51, 0.4);
  background-blend-mode: overlay;
}

.caret-icon2 {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.modal img {
  max-width: 80%;
  max-height: 80%;
}

.modal button {
  background: linear-gradient(
    45deg,
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent)
  );
  animation: gradientAnimation 700s infinite;
  background-size: 3000% 100%;
  border: 2px solid var(--gray);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0.5rem;
  color: var(--gray);
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  margin: 1rem;
  font-family: var(--f5);
  cursor: pointer;
}

.modal button:hover {
  transform: scale(1.1);
}

.carousel-nav {
  display: flex;
}

.full-repo-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--gray);
}

.pc-bottom {
  margin-top: 10%;
}

@media only screen and (max-width: 1550px) {
  .text-and-image {
    flex-direction: column;
  }

  .project-content p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .project-content img {
    height: revert;
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .projects-wrapper h2 {
    font-size: 2.5rem;
  }

  .project-column {
    width: 100%;
  }

  .project-title-container {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  .project-title-container img {
    height: 25px;
  }

  .projects-container h5 {
    font-size: 0.75rem;
  }

  .project-content p {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .outer-project-column {
    width: 100%;
  }
}
