.footer-container {
  background: #333;
  color: var(--gray);
  font-family: var(--f5);
  font-size: 1.5rem;
  letter-spacing: 0.25rem;
  line-height: 1.7;
  text-align: center;
  padding: 1rem;
}

.footer-container h4 {
  font-weight: normal;
}

.footer-container.light {
  background: var(--gray);
  color: #333;
}

@media only screen and (max-width: 1067px) {
  .footer-container h4 {
    font-size: 1rem;
  }
}
