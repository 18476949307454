.header-container {
  margin: 0;
  display: flex;
  justify-content: center;
  padding-top: 0;
  background: transparent;
}

.header-container h1 {
  font-family: var(--f3);
  font-size: 4rem;
  color: var(--gray);
  letter-spacing: 0.6rem;
  text-align: center;
  margin: 0 0.5rem 5%;
}

@media only screen and (max-width: 1250px) {
  .header-container h1 {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .header-container h1 {
    font-size: 3rem;
    margin-top: 8%;
  }
}
