.chart-container {
  margin: 5% auto;
  width: 80%;
}

.chart-container h2 {
  text-align: center;
  font-family: var(--f6);
  font-size: 3rem;
  color: var(--gray);
  letter-spacing: 0.5rem;
  animation: webBanFade 2s ease;
}

.update-title.light {
  color: #333;
}

@media only screen and (max-width: 1200px) {
  .chart-container tspan {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .chart-container {
    display: none;
  }
}
