.quote-form-container {
  margin-bottom: 3%;
}

.quote-form-container h4 {
  text-align: center;
  font-size: 3rem;
  font-family: var(--f6);
  color: var(--gray);
  letter-spacing: 0.5rem;
  margin: 0 auto 2rem;
  text-shadow: -3px 3px 6px #000;
}

.info-row {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.drop-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.check-row {
  display: flex;
  justify-content: center;
  gap: 3%;
}

.quote-form-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.quote-form-row label {
  text-align: center;
}

.quote-form-row option,
.quote-form-row select,
.quote-form-row input {
  background: #333;
  box-shadow: inset 2px 2px 4px #000;
  padding: 0.25rem;
}

.details-row label,
.check-row label,
.drop-row label,
.info-row label {
  font-family: var(--f3);
  color: var(--gray);
  margin-bottom: 0.5rem;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
}

.check-row label {
  font-size: 1.5rem;
  white-space: nowrap;
}

.check-row select,
.drop-row select,
.info-row input {
  min-height: 50px;
  box-sizing: border-box;
  min-width: 300px;
  border-radius: 15px;
  padding: 0.5rem;
}

.check-row select {
  min-width: 50px;
  border-radius: 10px;
}

.check-row select,
.check-row input,
.info-row input,
.drop-row select,
.drop-row option {
  text-align: center;
  color: var(--gray);
  font-family: var(--f3);
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
}

.check-row select:hover,
.drop-row select:hover {
  cursor: pointer;
}

.info-row input {
  text-align: left;
}

.quote-grey {
  max-width: 200px;
  font-weight: bold;
}

.small-boxes {
  display: flex;
  justify-content: space-around;
}

.check-row option,
.drop-row option {
  font-weight: bold;
}

.check-row.light option,
.drop-row.light option,
.quote-grey.light {
  color: #333;
  background: var(--gray);
}

.small-boxes2 {
  width: 25%;
}

.details-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details {
  min-width: 500px;
  min-height: 250px;
  resize: none;
  box-shadow: inset 3px 3px 6px #000;
  border: 2px inset #333;
  border-radius: 15px;
  background: #333;
  padding: 0.5rem;
  color: var(--gray);
  font-family: var(--f3);
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

.quote-submit-container {
  display: flex;
  justify-content: center;
}

.quote-success-container {
  display: flex;
  justify-content: center;
  color: var(--gray);
  font-size: 1.5rem;
}

@media only screen and (max-width: 1067px) {
  .check-row,
  .info-row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .quote-form-container h4 {
    font-size: 2.5rem;
  }

  .quote-non-resizable {
    min-height: 200px;
    min-width: 275px;
  }

  .check-row label {
    font-size: 1rem;
  }
}
