.navigation-bar {
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(
    45deg,
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent),
    var(--dgreen),
    var(--accent),
    var(--pink),
    var(--accent)
  );
  animation: gradientAnimation 700s infinite;
  background-size: 3000% 100%;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0 15px #000;
  margin-bottom: 1rem;
  border-bottom: outset 2px #333;
}

.navigation-bar a {
  text-decoration: none;
  color: var(--gray);
  font-size: 1.25rem;
  font-family: var(--f5);
  letter-spacing: 0.1rem;
  transition: all ease 0.3s;
}

.navigation-bar a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 4px;
}

@media only screen and (max-width: 767px) {
  .navigation-bar {
    flex-direction: column;
    gap: 0.75rem;
  }
}
